body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans Variable', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", Arial, Helvetica, sans-serif;
}
:root {
  /* Colours */
  --color-text: #fff;
  --color-primary: #576cbc;
  --color-secondary: #19376d;
  --color-dark: #0b2447;
  --color-bg: #04152d;

  /* Fonts */
  /* --font-open: "open-sans"; */
}
