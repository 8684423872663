@import "../../index.css";

.container-contact{
    color: var(--color-text);
    margin-top: 40px;
    background-color: var(--color-secondary);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100vw;
    padding: 2% 2%;
    gap: 10px;
}
.contacticon{
    /* margin-right: 1%; */
}

.text h2 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 4px;
  }

.text p{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    gap:20px
}

.link{
    display: flex;
    align-items: center;
    gap: 25%;
    /* padding: 1%; */
}

.link a{
    color: var(--color-text);
    text-decoration: none;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 1.5px;
}

@media screen and (max-width:830px) {
    
    .container{
        flex-direction: column;
        gap: 18px;
    }
    .text{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .text p{
        margin-left: 1%;
    }
    .text h2 {
        font-size: 22px;
        letter-spacing: 2px;
      }
    .link{
        gap: 10px;
       
    }
    .link a{
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 1px;
    }
}