@import "../../../index.css";



.navbar{
  background: #5251511a;
    position: relative;
    /* position: sticky; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 20px 30px;
   
    /* z-index: 3; */
    /* margin: 0 10%; */
    top: 0;
  z-index: 999;   
  
}

.menuItems a {

  cursor: pointer;
}

.title {
    font-size: 25px;
    text-decoration: none;
    font-weight: 700;
    color: var(--color-text);

}

.menuBtn {
    display: none;
  }
  
.menu {
    display: flex;
  }

.menuItems {
    display: flex;
    gap: 40px;
    list-style: none;
}

.menuItems a {
    font-size: 20px;
    color: var(--color-text);
    text-decoration: none;
}

@media screen and (max-width: 800px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
      }
    
      .menu {
        position: absolute;
        right: 0;
        margin-right: 10%;
        flex-direction: column;
        align-items: flex-end;
        gap: 11px;
        z-index: 3;
      }
    
      .menuBtn {
        display: block;
        cursor: pointer;
      }
    
      .menuItems {
        display: none;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        border-radius: 10px;
        background: rgb(25, 55, 109);
        background: linear-gradient(
          0deg,
          rgba(25, 55, 109, 0.2) 0%,
          rgba(25, 55, 109, 1) 100%
        );
        padding: 24px 33px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
      }
    
      .menuOpen {
        display: flex;
        z-index: 1;
      }
    
      
    

}