@import "../../index.css";

.container-experience {
    color: var(--color-text);
    margin-left: 5%;
  }
  
  .title-experience {
    color: var(--color-text);
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    margin-top: 2%;
  }
  
  .content-experience {
    display: flex;
    justify-content: space-evenly;
    margin-top: 14px;
  }
  
  .skills {
    /* width: 90%; */
    /* display: flex; */
    width: 45%;
  display: flex;
  flex-wrap: wrap;
  gap: 37px;

  
  }
  
  .skill {
   
    padding-top: 1%;
    /* flex-direction: column; */
    margin: 2%;
    display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
   
  }
  .skill-details{
    display: flex;
    border: 1px solid #000;
    flex-wrap: wrap;
    
    /* flex-direction: row-reverse; */
  }
  .skillImageContainer {
    /* background-color: var(--color-secondary); */
    border-radius: 100%;
    display: flex;
     justify-content: center;
     align-items: center;
     justify-content: center;
    /* width: 120px;
    height: 120px; */
  }
  
  .skillImageContainer img {
    width: 75px;
  }
  
  .skill p {
    font-size: 25px;
    font-family: var(--font-open);
    font-weight: 500;
  }
  
  .projectdetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
  
  .projectdetailsItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 30px; */
    background: #19376d;
    background: linear-gradient(90deg, #19376d 0%, rgba(25, 55, 109, 0) 100%);
    border-radius: 20px;
    padding: 25px;
  }
  

  
  .projectdetailsItemDetails {
    font-family: var(--font-open);
  }
  
  .projectdetailsItemDetails h3 {
    font-size: 25px;
    font-weight: 100;
  }
  
  .projectdetailsItemDetails p {
    font-size: 15px;
    font-weight: 300;
    padding: 1%;
  }
  
  .projectdetailsItemDetails ul {
    /* margin-top: 6px; */
    list-style-position: inside;
    font-size: 18px;
    list-style-type: revert;
    margin-left: 17px;
    font-style:unset;
    text-decoration: dotted;
  }
  .explist{
    padding-bottom: 10px;
  }
  
  @media screen and (max-width: 830px) {
    .content-experience {
      flex-direction: column;
      align-items: center;
      gap: 34px;
    }
  
    .skills {
      width: 100%;
      /* flex-direction: row; */
      flex-direction: column;
      justify-content: center;
    }
  
    .projectdetails {
      width: 100%;
      gap: 9px;
    }

    .skillImageContainer img {
      width: 45px;
    }

    .skill p {
      font-size: 15px;
      font-weight: 400;
    }

    .skill {
   
      padding-top: 3%;
      /* flex-direction: column; */
      margin: 2%;
    gap: 8px;
     
    }
    .projectdetailsItemDetails h3 {
      font-size: 22px;
    }
    .projectdetailsItemDetails p {
      font-size: 12px;
    }
    .explist {
      font-size: 16px;
      padding-bottom: 7px;
    }
  }
  
  