@import "./index.css";

.App {
  background-color: var(--color-bg);
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
